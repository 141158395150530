:root {
  --error-validation-bg-color: rgba(208, 2, 27, 0.9);
}

.line-pricing-group-container .v-expansion-panel {
  box-shadow: none;
}
.line-pricing-group-container .line-pricing-group-header span {
  font-size: 1.4rem;
  font-weight: 600;
}
.line-pricing-group-container .save-btn.v-btn {
  margin-right: 0;
  text-transform: capitalize;
}
.line-pricing-group-container .add-btn {
  margin-left: 0;
}