:root {
  --error-validation-bg-color: rgba(208, 2, 27, 0.9);
}

.scenario-selector-row .btn-delete {
  min-width: unset !important;
  padding: unset !important;
  height: 2.5rem !important;
  width: 2.5rem;
}
.scenario-selector-row .alert-dialog {
  width: 0;
  height: 0;
}
.scenario-selector-row .v-messages {
  display: none;
}
.scenario-selector-row .v-label {
  font-size: 1.3rem;
}
.scenario-selector-row .v-input__slot {
  margin: 0 !important;
}
.scenario-selector-row .v-input--radio-group label {
  color: #37424a;
}
.scenario-selector-row .v-radio--is-disabled label {
  color: #37424a;
}
.scenario-selector-row .v-radio .v-icon {
  color: #2f477c !important;
}
.scenario-selector-row .v-radio {
  margin: 0 !important;
}
.scenario-selector-row input {
  padding: 0;
  text-align: right;
  font-size: 1.3rem;
}
.scenario-selector-row .v-input {
  padding: 0;
  margin: 0;
}
.scenario-selector-row .v-text-field__details {
  display: none;
}