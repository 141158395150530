::v-deep .v-tabs-items {
  background-color: transparent !important;
}

.pricing-tabs .pricing-tabs__tab {
  min-width: 15rem;
  padding: 1rem;
  justify-content: left;
  border: 1px solid #dddddd;
  border-left: 0.3rem solid #aecadb;
  background-color: #f9f9fa;
  font-size: 1.2rem;
  letter-spacing: normal;
}
.pricing-tabs .pricing-tabs__tab--active:not(.v-tab--disabled) {
  border-bottom: none;
  background-color: white;
  font-weight: 600;
}
.pricing-tabs__spacer {
  width: 0.5rem;
  border-bottom: 1px solid #dddddd;
}
.pricing-tabs__tab-button {
  border-bottom: 1px solid #dddddd;
}
.pricing-tabs__tab-button-spacer {
  width: 0.9rem;
  border-bottom: 1px solid #dddddd;
}
.pricing-tabs__filler {
  flex-grow: 1;
  border-bottom: 1px solid #dddddd;
}
.pricing-tabs__notifications-icon {
  padding-right: 1rem;
  border-bottom: 1px solid #dddddd;
}