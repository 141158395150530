:root {
  --error-validation-bg-color: rgba(208, 2, 27, 0.9);
}

.main-header th {
  position: relative;
}

#buttons-bar {
  max-width: 20rem;
}

.v-list-item__title {
  font-size: 1.5rem;
}

.v-list-item {
  min-height: 35px;
}

.no-data-slot {
  padding-left: 2.2rem;
}

.input-screen-page-wrapper .table-cell::v-deep .tooltipped-truncated-field {
  position: unset;
}

.date-attribute-cell {
  position: relative;
}

.diff-background {
  background-color: rgba(255, 165, 0, 0.2784313725);
}

.error-background {
  background-color: #eacece;
}

.pale {
  opacity: 0.7;
}

::v-deep .sidebar-toggle.v-icon {
  color: red !important;
  font-family: "Material Icons Outlined";
  font-feature-settings: "liga";
  font-size: 20px;
}

.no-data {
  font-size: 1.4rem;
}

.inputs-actions {
  height: 50px;
  padding: 8px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  background-color: white;
  gap: 16px;
  border-bottom: 1px solid #aeaeae;
}
.inputs-actions__btn-container {
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 8px;
}
.inputs-actions .v-text-field__details {
  display: none;
}

.inputs-footer {
  height: 50px;
  padding: 8px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  background-color: white;
  gap: 16px;
  border-bottom: 1px solid #aeaeae;
}
.inputs-footer__row-count {
  font-size: 12px;
}
.inputs-footer__msg-container .message {
  color: #ca7c00;
  font-size: 12px;
}
.inputs-footer__btn-container {
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 8px;
}
.inputs-footer .v-text-field__details {
  display: none;
}