:root {
  --error-validation-bg-color: rgba(208, 2, 27, 0.9);
}

.border-top {
  border-top: 0.1rem solid #c2c2c2;
}

.border-left {
  border-left: 0.1rem solid #c2c2c2;
}

.border-bottom {
  border-bottom: 0.1rem solid #c2c2c2;
}

.block {
  margin: 50px;
}

.goto-row-separator {
  border-bottom: 0.1rem solid #c2c2c2;
}

.row .col .ok-btn {
  color: #ffffff;
  background-color: #2f477c !important;
  border-color: #2f477c;
}

.checkbox::v-deep {
  transform: scale(0.65);
  transform-origin: left;
}
.checkbox::v-deep .v-input__prepend-outer {
  margin-right: 0;
}