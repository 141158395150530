:root {
  --error-validation-bg-color: rgba(208, 2, 27, 0.9);
}

.v-card .body.v-card__text {
  margin-bottom: 7rem;
  font-size: 1.5rem;
}
.v-card .header-text {
  padding-top: 2rem;
  font-size: 2rem;
  color: black;
}
.v-card .btn-group.v-card__actions .cancel-btn {
  color: #3a454d;
  border-color: #3a454d;
}
.v-card .btn-group.v-card__actions .ok-btn {
  color: #ffffff;
  background-color: #2f477c;
  border-color: #2f477c;
}

#unsaved-save-button {
  color: #ffffff;
  background-color: #2f477c;
  border-color: #2f477c;
}

#unsaved-discard-button {
  color: #3a454d;
  border-color: #3a454d;
}

.pg-dropdown-item-text {
  font-size: 1.5rem;
}

.header-text {
  padding-top: 0.5rem;
  font-size: 2rem;
  color: black;
}

.v-btn {
  font-size: 1.5rem;
}

.error-message {
  color: #c13939;
}