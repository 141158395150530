:root {
  --error-validation-bg-color: rgba(208, 2, 27, 0.9);
}

.products-table table th {
  background-color: #ffffff !important;
}
.products-table tbody td {
  font-size: 1.5rem;
  padding: 0 10px;
}
.products-table tbody tr {
  background-color: #ffffff !important;
}
.products-table tbody tr.even-rows {
  background-color: #f0f5f6 !important;
}
.products-table tbody tr:hover {
  background-color: #a0ccff !important;
}

.impact {
  background-color: rgba(0, 160, 223, 0.1764705882);
  width: 7rem;
}

.border-right {
  border-right: 0.1rem solid rgba(0, 0, 0, 0.12);
}

.border-left {
  border-left: 0.1rem solid rgba(0, 0, 0, 0.12);
}

.update-disabled {
  background-color: #c2c2c2 !important;
}