:root {
  --error-validation-bg-color: rgba(208, 2, 27, 0.9);
}

.panel-header {
  background-color: #d3dee6 !important;
}
.panel-header::v-deep .v-label, .panel-header::v-deep .v-chip__content {
  font-size: 1.2rem;
}

.filter-text {
  color: #2f477c;
  font-size: 1.25rem;
}

.filter-text-title {
  font-size: 1.25rem;
}