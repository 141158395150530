:root {
  --error-validation-bg-color: rgba(208, 2, 27, 0.9);
}

.checkbox-row .v-messages {
  display: none;
}
.checkbox-row .v-input__slot {
  margin-bottom: unset !important;
}
.checkbox-row .release-text {
  font-size: 1.25rem;
  padding-top: 0.5rem;
}
.checkbox-row .alert-cell {
  background-color: #f2f3f4 !important;
  border-bottom: none !important;
  min-width: 5.4rem !important;
  border-top: none !important;
}
.checkbox-row .category-heading {
  font-size: 1.4rem;
}
.checkbox-row .hierarchy-heading {
  font-size: 1.25rem;
}