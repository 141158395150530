:root {
  --error-validation-bg-color: rgba(208, 2, 27, 0.9);
}

.rule-heading {
  color: #4a4a4a;
  font-size: 1.3rem;
  font-weight: 600;
  background-color: #e1e8ed;
  padding: 0.7rem;
  margin: 0 0 1rem 0;
  border-top: solid 0.1rem #6e8b9c;
}

.rule-index {
  min-width: 3rem;
  max-width: fit-content;
  min-height: 3.5rem;
  max-height: 3.5rem;
  margin: 0 1.5rem 0.5rem 0.5rem;
  font-size: 1.25rem;
  background-color: #d2dee6;
  padding: 1rem 1rem 0 1rem;
}

.rule-draggable-icon {
  max-width: fit-content;
  margin: 0 0.5rem 0.5rem 0.5rem;
  font-size: 1.25rem;
  padding: 0.3rem 0 0 0.3rem;
}

.rule-drag-here {
  font-size: 1.25rem;
  border: dashed 0.1rem #d2dee6;
  padding: 1rem;
  width: 100%;
}

.no-index {
  min-width: 5rem;
  max-width: 5rem;
}

.ruleset {
  margin: 0.5rem 0.5rem 0 0.5rem;
}

.opaque {
  opacity: 0.5;
}

#apply-button {
  color: white;
  margin-right: 7rem;
  margin-top: 4px;
  background-color: #68be4f;
}

.control-buttons {
  border-top: solid 1px #6e8b9c;
  padding: 1rem;
  margin-top: 1rem;
}

.add-rule-text {
  font-size: 1.25rem;
  margin-left: 1rem;
}

.ruleset-container {
  margin-bottom: 1rem;
}

.ruleset-container:last-of-type {
  margin-bottom: 1rem;
}

.add-rule-icon {
  color: #2f477c;
}

.add-rule-container {
  display: flex;
  align-items: center;
  flex: 1 0 0;
  min-width: 0;
}

.v-btn {
  font-size: 1.25rem;
}

.row {
  margin: 0;
}

.rule-container {
  min-width: 0;
}

.store-group-filter {
  width: 30%;
}

.snackbar-text {
  font-size: 1.4rem;
}