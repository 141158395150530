:root {
  --error-validation-bg-color: rgba(208, 2, 27, 0.9);
}

.price-pointing .v-input__prepend-outer {
  margin-right: unset !important;
}
.price-pointing .v-text-field__details {
  display: none;
}
.price-pointing input {
  padding: unset !important;
}