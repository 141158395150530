:root {
  --error-validation-bg-color: rgba(208, 2, 27, 0.9);
}

.diagnostics {
  height: 100%;
  width: 100%;
  background-color: white;
  font-size: 1.4rem;
}
.diagnostics__container {
  padding-top: 1rem;
  display: flex;
}
.diagnostics__container--selector {
  width: 50%;
  padding-left: 1rem;
}
.diagnostics__container--selector__button {
  margin-top: 1rem;
}
.diagnostics__container--editor {
  padding-left: 1rem;
}
.diagnostics ::v-deep .v-messages {
  display: none;
}
.diagnostics ::v-deep .v-input__slot {
  margin: unset;
}