:root {
  --error-validation-bg-color: rgba(208, 2, 27, 0.9);
}

.hidden-header,
.hidden-cell {
  display: none;
}

.main-header th {
  position: relative;
  vertical-align: bottom;
}

.input-cell {
  width: 10%;
}

.v-list-item__title {
  font-size: 1.5rem;
}

.v-list-item {
  min-height: 35px;
}

.v-data-table--fixed-header thead tr:nth-child(1) th {
  box-shadow: none;
  font-weight: 600;
  height: 2rem;
}
.v-data-table--fixed-header thead tr:nth-child(2) th {
  top: 2rem;
  height: 2rem;
}
.v-data-table--fixed-header thead th.three-fixed:nth-child(3),
.v-data-table--fixed-header tbody td.three-fixed:nth-child(3) {
  left: 42rem;
}

.feature-toggle-btn {
  display: flex;
  align-items: center;
}

.competitor-row th {
  box-shadow: none !important;
}
.competitor-row th.box-shadow {
  box-shadow: inset 0 -0.1rem 0 rgba(0, 0, 0, 0.12) !important;
}
.competitor-wrapper {
  padding-top: 0.2rem;
}
.competitor-btn {
  background-color: unset !important;
  height: 2.1rem !important;
  border-radius: unset !important;
  box-shadow: unset !important;
  margin-left: 0.3rem !important;
  padding: 0 1rem 0 0 !important;
  border-left: #d3dee7 solid 0.2rem !important;
  border-top: #dddddd solid 0.1rem !important;
  border-right: #dddddd solid 0.1rem !important;
  color: #37424a !important;
  background-color: #f2f3f4 !important;
  letter-spacing: unset !important;
  cursor: pointer;
  margin-bottom: 0.1rem;
}
.competitor-btn.v-size--default {
  font-size: 1.2rem !important;
}
.competitor-btn.active {
  border-bottom: white solid 0.2rem !important;
  border-left: #aecadb solid 0.2rem !important;
  color: #2f477c !important;
  background-color: initial !important;
  cursor: unset;
  margin-bottom: -0.1rem;
}
.competitor-btn.theme--light.v-btn.v-btn--disabled:not(.v-btn--flat):not(.v-btn--text):not(.v-btn--outlined) {
  color: #37424a !important;
  background-color: #f2f3f4 !important;
  opacity: 0.5 !important;
}

.input-screen-page-wrapper .table-cell::v-deep .tooltipped-truncated-field {
  position: unset;
}