:root {
  --error-validation-bg-color: rgba(208, 2, 27, 0.9);
}

.hierarchy-level-item__group-end .actions {
  display: flex;
  flex-wrap: wrap-reverse;
  justify-content: flex-end;
  margin-left: 0.5rem;
}
.hierarchy-level-item__group-end .v-btn {
  font-size: 1.25rem;
}
.hierarchy-level-item__group-end .v-messages {
  display: none;
}
.hierarchy-level-item__group-end .v-input__slot {
  margin-bottom: unset !important;
}
.hierarchy-level-item__group-end .checkbox {
  padding-top: 0.1rem;
}
.hierarchy-level-item__group-end .hierarchy-level-item__approve-btn.v-btn {
  height: 2.4rem;
  padding: unset;
  margin-right: 0.5rem !important;
}
.hierarchy-level-item__group-end .hierarchy-level-item__approve-btn.v-btn.approval-workflow.theme--light.v-btn.v-btn--disabled:not(.v-btn--flat):not(.v-btn--text):not(.v-btn--outlined) {
  background-color: #4caf50 !important;
}
.hierarchy-level-item__group-end .hierarchy-level-item__recalculate-btn.v-btn {
  background-color: #2f477c !important;
  color: white;
  height: 2.4rem;
  padding: unset;
  margin-right: 0 !important;
}
.hierarchy-level-item__group-end .hierarchy-level-item__recalculate-btn.v-btn.unsaved-kpis {
  background-color: #e95f00 !important;
}
.hierarchy-level-item__group-end .hierarchy-level-item__recalculate-btn.v-btn.unsaved-kpis.theme--light.v-btn.v-btn--disabled:not(.v-btn--flat):not(.v-btn--text):not(.v-btn--outlined) {
  background-color: #e95f00 !important;
}
.hierarchy-level-item__group-end .hierarchy-level-item__recalculate-btn.v-btn .v-btn__loader .v-progress-circular.v-progress-circular--indeterminate {
  height: 1.8rem !important;
  width: 1.8rem !important;
}

.alert-cell {
  background-color: #f2f3f4 !important;
  border-bottom: none !important;
  width: 5.4rem !important;
  border-top: none !important;
}

.hierarchy-level-item__heading {
  font-size: 1.8rem;
  font-weight: 700;
}
.hierarchy-level-item--level-0 td:not(.alert-cell) {
  border-top: solid 0.2rem #00a0e2;
}
.hierarchy-level-item--level-0 .hierarchy-level-item__heading {
  color: #00a0e2;
}
.hierarchy-level-item--level-1 td:not(.alert-cell) {
  border-top: solid 0.1rem #9b9b9b;
}
.hierarchy-level-item--level-1 .hierarchy-level-item__recalculate-btn.v-btn {
  min-width: 3rem;
  width: 3rem;
}
.hierarchy-level-item--level-2 td:not(.alert-cell) {
  border-bottom: solid 0.1rem #9b9b9b;
}
.hierarchy-level-item--level-2 .hierarchy-level-item__recalculate-btn.v-btn {
  min-width: 3rem;
  width: 3rem;
}
.hierarchy-level-item--level-3 td {
  border-bottom: solid 0.1rem #9b9b9b;
}
.hierarchy-level-item--level-3 td.hierarchy-level-item__heading {
  font-weight: 300;
}
.hierarchy-level-item--level-3 td.hierarchy-level-item__heading.disabled {
  color: #8ba4b7;
}
.hierarchy-level-item__main {
  color: #4a4a4a;
}
.hierarchy-level-item__main td:not(.alert-cell) {
  font-size: 1.3rem;
  line-height: 1.7rem;
}
.hierarchy-level-item__main td:not(.alert-cell).highlight {
  background-color: #f2f7fd;
}
.hierarchy-level-item__data-cell {
  border-bottom: solid 0.1rem #9b9b9b;
  font-weight: 600;
}
.hierarchy-level-item__data-cell input {
  padding: 0;
  text-align: right;
  font-size: 1.3rem;
}
.hierarchy-level-item__data-cell .v-input {
  padding: 0;
  margin: 0;
}
.hierarchy-level-item__data-cell .v-text-field__details {
  display: none;
}
.hierarchy-level-item__data-cell .v-input__slot {
  margin-bottom: 0;
}

.border-right {
  border-right: 0.1rem solid #9b9b9b;
}

.border-bottom {
  border-bottom: 0.1rem solid #9b9b9b;
}

.remove-margin {
  margin: 0;
}

.store-group-expand {
  text-align: right;
}

.recalculate-dialog {
  font-size: 1.3rem;
  line-height: 2rem;
}