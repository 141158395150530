:root {
  --error-validation-bg-color: rgba(208, 2, 27, 0.9);
}

.expansion {
  width: 100%;
  background-color: #e7edef;
}
.expansion .margin-splits-expansion-header {
  font-weight: bold;
  font-size: 1.2rem;
  padding: 1rem 1rem 0 1rem;
}
.expansion .margin-splits-expansion-list {
  height: 50rem;
  overflow-y: scroll;
  position: relative;
}
.expansion .search-field {
  max-width: unset;
}
.expansion .no-results {
  text-align: center;
}
.expansion .list-item-div:nth-child(odd) .splits-list-item {
  background-color: #f0f5f6;
}
.expansion .splits-list-item {
  min-height: 2rem;
}
.expansion .splits-list-item-icon {
  font-size: 2rem;
  color: #2f477c;
}
.expansion .splits-list-item-avatar.v-list-item__avatar {
  height: 2rem !important;
  min-width: 2rem !important;
  width: 2rem !important;
}
.expansion .splits-list-item-avatar.v-list-item__avatar:first-child {
  margin-right: 1.6rem;
}
.expansion .splits-list-item.clicked {
  background-color: #a0ccff !important;
}