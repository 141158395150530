.v-application--wrap {
  min-height: calc(100vh - 20rem) !important;
}
.v-application--wrap .v-snack__content {
  font-size: 1.25rem;
  line-height: 21px;
}
.v-application--wrap .notification-wrapper {
  word-wrap: break-word;
  width: 100%;
  color: white;
  font-size: 14px;
}
.v-application--wrap .v-snack__wrapper {
  border-radius: 4px;
}