.grid-view tbody tr.v-data-table__empty-wrapper > td {
  padding: 0 !important;
}

.progress-cell-grid-view {
  height: 0.4rem !important;
}

.progress-bar-grid-view {
  width: calc(100% - 5.4rem);
}

.override-calendar-button {
  left: 1.6rem !important;
  margin-top: 0.3rem !important;
  padding: 0 !important;
  min-width: unset !important;
}
.override-calendar-button > .v-btn__content {
  padding: 0 !important;
}

.override-container {
  position: absolute !important;
  right: -0.5rem;
}

.right-aligned-text-inside-tooltip {
  margin-left: auto;
}

.active .theme--light.v-icon:focus::after {
  opacity: 0;
}

.search-box .v-label {
  font-size: 1.2rem;
}
.search-box input {
  padding: 0;
}