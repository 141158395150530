:root {
  --error-validation-bg-color: rgba(208, 2, 27, 0.9);
}

.ag-menu-header {
  font-weight: normal;
  display: flex;
  background-color: #ffffff;
}
.ag-menu-header .ag-cell-label-container {
  display: flex;
}
.ag-menu-header .ag-cell-label-container .sort-none {
  padding-left: 2.2rem;
}
.ag-menu-header ::v-deep .menu-icon {
  padding-right: 0.5rem;
}