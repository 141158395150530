:root {
  --error-validation-bg-color: rgba(208, 2, 27, 0.9);
}

.expand-btn {
  outline: none;
  text-align: center;
}
.expand-btn--disabled {
  cursor: not-allowed;
}
.expand-btn--bg {
  border-radius: 0.3rem;
}
.expand-btn--bg .expand-btn__icon {
  color: #ffffff;
}
.expand-btn--horizontal {
  transform: rotate(270deg);
}
.expand-btn__icon {
  vertical-align: middle;
  color: #2f477c;
  width: 1.6rem;
  height: 1.6rem;
  font-size: 1.6rem;
}