:root {
  --error-validation-bg-color: rgba(208, 2, 27, 0.9);
}

.sidebar-label {
  font-size: 1.2rem;
  font-weight: bold;
  text-orientation: sideways-right;
  text-transform: uppercase;
  writing-mode: vertical-rl;
  transform: rotate(-180deg);
  background-color: #d3dee7;
  padding-left: 0.2rem;
  color: #2f477c;
  padding: 1rem 0.1rem 0.1rem 0.1rem;
  border-radius: 4px;
}

.expand-icon {
  color: #2f477c;
  font-size: 2rem;
}

.main-sidebar {
  position: absolute;
  top: 0;
  right: 0;
  z-index: 100;
  transition: all 0.5s;
  box-shadow: unset !important;
  display: flex;
  flex-direction: row;
}
.main-sidebar--collapsed {
  width: 3rem;
}
.main-sidebar__expanded-content {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  width: 25rem;
  background-color: #ffffff;
  box-shadow: -8px 8px 3px -4px #e7edef;
}