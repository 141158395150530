:root {
  --error-validation-bg-color: rgba(208, 2, 27, 0.9);
}

.dropdown-list-item__container {
  position: sticky;
  bottom: 0;
  background: #ffffff;
}
.dropdown-list-item__avatar {
  height: 2rem !important;
  min-width: 2rem !important;
  width: 2rem !important;
}
.dropdown-list-item__avatar:first-child {
  margin-right: 1rem;
}
.dropdown-list-item__icon {
  font-size: 2rem;
  color: #2f477c;
}
.dropdown-list-item__icon--disabled {
  opacity: 0.6;
}