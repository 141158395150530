:root {
  --error-validation-bg-color: rgba(208, 2, 27, 0.9);
}

.text-row {
  font-weight: normal;
  margin-left: -1.7rem;
}

.empty-reveal {
  padding-top: 0.2rem;
}