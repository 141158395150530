:root {
  --error-validation-bg-color: rgba(208, 2, 27, 0.9);
}

.translation-editor {
  height: 100%;
  width: 100%;
  background-color: white;
  font-size: 1.4rem;
}
.translation-editor__container {
  padding-top: 1rem;
  display: flex;
  flex-direction: column;
}
.translation-editor__container--button {
  padding-left: 1rem;
  margin-top: 1rem;
  margin-bottom: 1rem;
}
.translation-editor__container--editor {
  padding-left: 1rem;
}
.translation-editor ::v-deep .v-messages {
  display: none;
}
.translation-editor ::v-deep .v-input__slot {
  margin: unset;
}