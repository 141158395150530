:root {
  --error-validation-bg-color: rgba(208, 2, 27, 0.9);
}

.edge-group path {
  stroke-width: 2px;
}
.edge-group path:hover {
  stroke-width: 3px !important;
}
.edge-group:hover path {
  stroke-width: 1px;
}

.tooltip {
  z-index: 2;
  min-width: 20rem;
  position: absolute;
  text-align: left;
  padding: 1rem;
  font-size: 1.2rem;
  border: 0.1rem solid #00a0e2;
  border-left: 0.2rem solid #00a0e2;
  box-shadow: 0.5rem 0.5rem 1rem #c2c2c2;
  background-color: #ffffff;
}
.tooltip #architectureGraphToolTipBody {
  overflow: auto;
  max-height: 12rem;
}