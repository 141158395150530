:root {
  --error-validation-bg-color: rgba(208, 2, 27, 0.9);
}

.validation-fail {
  color: red;
  font-size: 1.4rem;
  vertical-align: middle;
}

.theme--light.line-priced-products.v-data-table {
  border-bottom: 0rem;
}
.theme--light.line-priced-products.v-data-table .v-data-table__wrapper {
  position: relative;
}
.theme--light.line-priced-products.v-data-table table th.line-price-factor-header {
  min-width: 120px;
}
.theme--light.line-priced-products.v-data-table table tbody td.line-price-factor {
  padding: 0;
  width: 5rem;
  background: #f2f3f4;
}
.theme--light.line-priced-products.v-data-table table tbody td.line-price-factor .line-price-factor-input {
  min-width: 120px;
}
.theme--light.line-priced-products.v-data-table table tbody td.line-price-factor .line-price-factor-input input {
  background: #ffffff;
}
.theme--light.line-priced-products.v-data-table table tbody td.line-price-factor .v-text-field__details {
  position: absolute;
  right: 0;
}
.theme--light.line-priced-products.v-data-table table tbody td.line-price-factor .v-input {
  padding-top: 0;
  height: 2.2rem;
  font-size: 1.2rem;
}
.theme--light.line-priced-products.v-data-table table tbody td.line-price-factor .v-input .v-text-field__slot input {
  padding: 0;
  padding-left: 0.5rem;
}

.v-expansion-panel--active > .line-pricing-group.v-expansion-panel-header {
  border-bottom: 0.1rem solid #9b9b9b;
  margin-bottom: 1.5rem;
}