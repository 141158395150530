.hierarchy__row--headers > td {
  padding-top: 0;
  padding-bottom: 0;
  font-size: 1.2rem;
  font-weight: 700;
}

th {
  font-size: 1rem;
  font-weight: 600;
}