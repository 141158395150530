:root {
  --error-validation-bg-color: rgba(208, 2, 27, 0.9);
}

.drag-zone {
  padding-right: 1rem;
  padding-left: 2rem;
  color: #c2c2c2;
  margin-right: auto;
  margin-left: unset;
  margin-top: 1rem;
  width: 93%;
}

.attribute-list,
.architecture-drivers {
  font-size: 1.2rem;
}

.v-expansion-panel {
  box-shadow: none;
}

.text-truncate {
  line-height: unset !important;
}

.panel {
  background-color: #e7edef !important;
}

.delete-icon {
  color: #29477a;
  padding-top: 10px !important;
}

.delete-icon,
.disabled-drag-icon,
.drag-icon {
  font-size: 1.5rem;
}

.disabled-drag-icon {
  color: #f2f3f4 !important;
  cursor: unset;
}

.scroll {
  max-height: 30rem;
  overflow: scroll;
}

.spinner-wrapper {
  justify-content: center;
}