:root {
  --error-validation-bg-color: rgba(208, 2, 27, 0.9);
}

.wholesale-grid-card {
  border-left: 5px solid #2f477c;
  min-height: 100%;
  min-width: 100%;
  margin: 0;
  padding: 0;
}
.wholesale-grid-card .grid-description {
  font-size: 1.4rem;
  font-weight: 600;
  letter-spacing: 0;
  line-height: 1.6rem;
}
.wholesale-grid-card .completed-label {
  font-size: 1.2rem;
  color: #37424a;
  text-align: center;
}
.wholesale-grid-card .hint {
  font-size: 1.2rem;
  color: #37424a;
  text-align: center;
  font-weight: 600;
  width: 100%;
}

.wholesale-grid-card {
  position: relative;
}
.wholesale-grid-card.card-container {
  min-height: 23rem;
}
.wholesale-grid-card.card-container.border-error {
  border-color: #c13939;
}
.wholesale-grid-card.card-container.small {
  min-height: initial;
  padding-top: 2rem;
  padding-bottom: 1rem;
  cursor: pointer;
}
.wholesale-grid-card .close-icon {
  position: absolute;
  top: 1.5rem;
  right: 1.5rem;
  z-index: 1;
}
.wholesale-grid-card .close-action {
  color: #2f477c;
  font-size: 1.2rem;
  font-weight: 600;
}
.wholesale-grid-card .error-hint {
  color: #c13939;
}
.wholesale-grid-card .error-explanation {
  padding: 0 7rem;
  font-size: 1.2rem;
  color: #37424a;
  text-align: center;
  width: 100%;
}
.wholesale-grid-card .button-container {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: row;
}
.wholesale-grid-card .export-button {
  width: 100%;
}
.wholesale-grid-card .cta-button {
  margin-left: 0.5rem;
  width: 1.5rem;
  height: 1.5rem;
  display: flex;
  align-items: center;
  justify-content: center;
}
.wholesale-grid-card .cta-button.download svg {
  height: 1.3rem;
}
.wholesale-grid-card .cta-button.export svg {
  height: 2rem;
  width: 1.8rem;
}