:root {
  --error-validation-bg-color: rgba(208, 2, 27, 0.9);
}

.icons-container {
  position: relative;
}

.alerts-count-badge {
  cursor: pointer;
  position: absolute;
  right: -4px;
  top: 1px;
  background-color: #d7534e;
  font-size: 1.2rem !important;
  font-weight: bold;
  z-index: 2;
}

.alerts-icon {
  z-index: 1;
}

.alerts-text {
  color: white;
  font-size: 1.5rem;
}

.border-bottom {
  border-bottom: 0.1rem solid #d7534e;
}

.scrolling-card {
  overflow-y: auto;
  overflow-x: hidden;
  background-color: #723431;
  border: 4px solid #d7534e;
}