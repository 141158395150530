:root {
  --error-validation-bg-color: rgba(208, 2, 27, 0.9);
}

.error-page {
  margin-top: 10rem;
}
.error-page .title {
  font-size: 3rem !important;
}
.error-page .error-code {
  vertical-align: middle;
  font-size: 4rem;
  width: 20rem;
  height: 20rem;
  line-height: 20rem;
  display: inline-block;
  border: 0.5rem solid #d3dee7;
  border-radius: 50%;
}
.error-page .description {
  font-size: 2rem;
}