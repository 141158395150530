:root {
  --error-validation-bg-color: rgba(208, 2, 27, 0.9);
}

.line-pricing {
  box-shadow: none;
}
.line-pricing .expansion-body {
  padding: 0;
  background-color: #f2f3f4;
}

.products-header span {
  font-size: 1.4rem;
}
.products-header span.products-count {
  font-size: 1.2rem;
}

.add-products-btn.v-btn {
  margin-right: 0;
  text-transform: capitalize;
}

.justify-between {
  display: flex;
  justify-content: space-between;
}

::v-deep .ag-grid-box {
  height: 50rem;
  width: 100%;
}
::v-deep .ag-line-pricing-grid .ag-root-wrapper {
  border: none;
}
::v-deep .ag-line-pricing-grid .ag-tooltip {
  white-space: pre-line !important;
}
::v-deep .ag-line-pricing-grid .ag-row-hover {
  background: #a0ccff !important;
  background-color: #a0ccff !important;
}
::v-deep .ag-line-pricing-grid.ag-theme-custom .ag-row-selected.ag-row-even {
  background: #eff5f6;
}
::v-deep .ag-line-pricing-grid.ag-theme-custom .ag-row-selected.ag-row-odd {
  background: #ffffff;
}
::v-deep .ag-line-pricing-grid .ag-cell:first-child {
  padding-left: 10px;
}
::v-deep .ag-line-pricing-grid .ag-header-cell {
  padding-left: 0;
}
::v-deep .ag-line-pricing-grid .ag-header-cell:first-child {
  padding-left: 10px;
}
::v-deep .ag-line-pricing-grid .ag-header-cell:first-child .ag-header-cell-text {
  color: rgba(0, 0, 0, 0.87);
  font-weight: bold;
}
::v-deep .ag-line-pricing-grid .ag-header-cell:last-child {
  padding-left: 11px;
}
::v-deep .ag-line-pricing-grid .ag-header-cell .ag-header-cell-text {
  color: rgba(0, 0, 0, 0.54);
  font-weight: bold;
  white-space: normal;
}