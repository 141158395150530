:root {
  --error-validation-bg-color: rgba(208, 2, 27, 0.9);
}

.btn-toggle {
  opacity: 1;
}

.toggle-btn-active {
  color: #ffffff !important;
  background-color: #2f477c !important;
  border-color: #2f477c !important;
}

.toggle-btn-inactive {
  color: #2f477c !important;
  border-color: #2f477c !important;
}