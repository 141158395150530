:root {
  --error-validation-bg-color: rgba(208, 2, 27, 0.9);
}

.wholesale-hierarchy {
  padding: 1rem;
}

.root {
  font-weight: bold;
  font-size: 1.2rem;
  margin-right: 1.2rem;
  padding: 0.5rem 1rem 0.5rem 1rem;
  align-self: center;
}
.root.highlighted-node {
  background-color: #bbdeec;
}

.label-part + .label-part {
  margin-left: 1.2rem;
}

.grid-dropdown {
  min-width: 20rem;
  max-width: 20rem;
}