.icon-text {
  color: white;
  padding-top: 0.15rem;
}

.square-icon {
  font-size: 2.5rem;
  padding-top: 0.1rem;
}

.square-text {
  padding-top: 0.15rem;
}

.circle-icon {
  font-size: 2.5rem;
  padding-top: 0.3rem;
}

.fa-stack {
  cursor: pointer;
}

.pill-shape {
  height: 1.8rem;
  width: 3rem;
  background-color: white;
  border-style: solid;
  border-width: 1px;
  border-color: black;
  color: black;
  text-align: center;
  line-height: 1.2rem;
  cursor: pointer;
  border-radius: 1rem;
}
.pill-shape .pill-text {
  font-size: 1.2rem;
}