:root {
  --error-validation-bg-color: rgba(208, 2, 27, 0.9);
}

.main-header th {
  position: relative;
}

#buttons-bar {
  max-width: 20rem;
}

.v-list-item__title {
  font-size: 1.5rem;
}

.v-list-item {
  min-height: 35px;
}

.no-data-slot {
  padding-left: 2.2rem;
}

.input-screen-page-wrapper .table-cell::v-deep .tooltipped-truncated-field {
  position: unset;
}

.date-attribute-cell {
  position: relative;
}

.invalid-blocking {
  border-bottom: 0.2rem solid #c13939 !important;
}

.pale {
  opacity: 0.7;
}