:root {
  --error-validation-bg-color: rgba(208, 2, 27, 0.9);
}

.rtls-text-field-container {
  align-items: center;
  height: 100%;
}

:focus {
  outline: none;
  background: #ffffff;
}

.base-class {
  padding: 0.3rem;
}

.attribute-cell-error {
  outline: #d0021b auto 1px;
}

.attribute-cell-updated {
  outline: #00a0e2 auto 1px;
}

.override-cell-error,
.override-base-class,
.override-cell-unsaved-kpi {
  width: 6rem;
  margin-right: 0;
  text-align: right;
}

.override-cell-unsaved-kpi:focus {
  border-bottom: 1px solid #e95f00;
}

.override-cell-error {
  border-bottom: 1px solid #d0021b;
}