.pricing-tooltip {
  position: relative;
  display: flex;
}
.pricing-tooltip .tooltip-container {
  display: flex;
  position: absolute;
  z-index: 10000;
  visibility: hidden;
  width: auto;
  background-color: rgba(97, 97, 97, 0.9);
  color: #fff;
  text-align: center;
  border-radius: 0.4rem;
  padding: 0.5rem 1.6rem;
  text-transform: initial;
}
.pricing-tooltip .tooltip-container.top {
  bottom: 110%;
}
.pricing-tooltip .tooltip-container.top-left {
  bottom: 110%;
  right: 110%;
}
.pricing-tooltip .tooltip-container.top-middle {
  bottom: 110%;
  left: 55%;
}
.pricing-tooltip .tooltip-container.top-right {
  bottom: 110%;
  left: 110%;
}
.pricing-tooltip .tooltip-container.bottom {
  top: 110%;
}
.pricing-tooltip .tooltip-container.bottom-left {
  top: 110%;
  right: 110%;
}
.pricing-tooltip .tooltip-container.bottom-middle {
  top: 110%;
  left: 55%;
}
.pricing-tooltip .tooltip-container.bottom-right {
  top: 110%;
  left: 110%;
}
.pricing-tooltip .tooltip-container.tooltip-error {
  background-color: rgba(208, 2, 27, 0.9);
}
.pricing-tooltip .tooltip-container .tooltip-text {
  display: flex;
  width: max-content;
}
.pricing-tooltip .tooltip-container .tooltip-list {
  display: flex;
  flex-direction: column;
  width: max-content;
}
.pricing-tooltip .tooltip-container .tooltip-list-item {
  display: flex;
}
.pricing-tooltip .tooltip-container .tooltip-list-item span {
  display: flex;
  padding: 0.2rem;
  text-align: left;
}
.pricing-tooltip:hover .tooltip-container {
  visibility: visible;
}