:root {
  --error-validation-bg-color: rgba(208, 2, 27, 0.9);
}

.legend {
  padding: 0 0 24px 50px;
}
.legend label div {
  font-size: 1.2rem;
  font-weight: 600;
}

h3 span {
  font-weight: 200;
  color: #c2c2c2;
}

.v-input--selection-controls {
  margin-top: 0;
  padding-top: 0;
}