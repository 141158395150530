:root {
  --error-validation-bg-color: rgba(208, 2, 27, 0.9);
}

.hierarchy__table {
  width: 100%;
  border-collapse: separate;
  border-spacing: 0;
}
.hierarchy__row {
  background-color: #ffffff;
}
.hierarchy__row--group-headers th, .hierarchy__row--headers th {
  background: #f2f3f4;
  position: sticky;
  z-index: 6;
  text-align: left;
  padding: 0 1rem;
}
.hierarchy__row--group-headers th {
  top: 11.1rem;
}
.hierarchy__row--headers th {
  top: 15.7rem;
}
.hierarchy__row td {
  padding: 0.4rem 0.7rem;
  vertical-align: middle;
}