:root {
  --error-validation-bg-color: rgba(208, 2, 27, 0.9);
}

.pricing-json-editor {
  height: 100%;
  width: 100%;
  display: flex;
  flex-grow: 1;
  flex-direction: column;
}
.pricing-json-editor ::v-deep .jsoneditor-menu {
  background-color: #2f477c;
  border: unset;
}
.pricing-json-editor ::v-deep .jsoneditor {
  border: unset;
  height: 100%;
  background-color: white;
}
.pricing-json-editor ::v-deep .jsoneditor-contextmenu li {
  background-color: white;
}
.pricing-json-editor ::v-deep .jsoneditor-box {
  height: 60rem;
}
.pricing-json-editor ::v-deep .jsoneditor-mode-view {
  border-left: 2px #2f477c solid;
  height: 100%;
  background-color: white;
}
.pricing-json-editor ::v-deep .jsoneditor-navigation-bar,
.pricing-json-editor ::v-deep .jsoneditor-poweredBy,
.pricing-json-editor ::v-deep .jsoneditor-repair,
.pricing-json-editor ::v-deep .jsoneditor-sort,
.pricing-json-editor ::v-deep .jsoneditor-statusbar {
  display: none;
}
.pricing-json-editor ::v-deep .max-btn {
  display: unset;
}
.pricing-json-editor ::v-deep .jsoneditor-value,
.pricing-json-editor ::v-deep .jsoneditor-field,
.pricing-json-editor ::v-deep .jsoneditor-separator {
  line-height: 2rem;
}