:root {
  --error-validation-bg-color: rgba(208, 2, 27, 0.9);
}

.ag-wholesale-grid .ag-root-wrapper {
  border: none;
}
.ag-wholesale-grid .expand-icon {
  transform: scale(2);
  color: #2f477c;
}
.ag-wholesale-grid .ag-cell.show-cell {
  background: #ffffff;
  border-bottom: 1px solid #c2c2c2;
}
.ag-wholesale-grid .ag-cell.show-cell.offset-cell.baseline-offset-cell {
  background-color: #8ba4b7;
  color: #ffffff;
  justify-content: left;
}
.ag-wholesale-grid .ag-cell.show-cell.offset-cell {
  background-color: #d3dee7;
  border-left: 1px solid #c2c2c2;
  border-right: 1px solid #c2c2c2;
}
.ag-wholesale-grid .show-cell.tool-store-group-column {
  color: #6d7278;
  font-weight: 600;
  letter-spacing: 0;
}
.ag-wholesale-grid .ag-cell.ag-cell-value.updated-field {
  border-radius: 3px;
  border: 2px solid;
  border-color: #00a0e2;
}
.ag-wholesale-grid .ag-cell.ag-cell-value.invalid-numeric {
  border-radius: 3px;
  border: 2px solid;
  border-color: #d0021b;
}
.ag-wholesale-grid .main-tool-store-group-row {
  background: #cfe4f1;
}
.ag-wholesale-grid .main-tool-store-group-row .show-cell {
  background-color: #cfe4f1;
}