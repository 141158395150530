:root {
  --error-validation-bg-color: rgba(208, 2, 27, 0.9);
}

:root {
  --error-validation-bg-color: rgba(208, 2, 27, 0.9);
}

.nav-link {
  color: #0561a5;
  border-radius: 0%;
  padding-bottom: 0.5rem;
}
.nav-link:hover {
  text-decoration: none;
}
.nav-link.nav-link-dark {
  color: #ffffff;
}

.active-link {
  border-bottom: 0.5rem solid #0561a5;
  padding-bottom: 0rem;
}

.v-btn {
  font-size: 1.5rem;
}

.nav-tab-wrapper {
  height: 100%;
}
.nav-tab-wrapper > .nav-link {
  height: 100%;
}

.theme--light.v-list {
  background-color: #2f477c;
  color: #ffffff;
}
.theme--light.v-list .v-list-item--link {
  color: #ffffff;
  height: 30px;
}
.theme--light.v-list .v-list__tile {
  height: 3rem;
}
.theme--light.v-list .v-list-item__title {
  font-size: 1.5rem;
}
.theme--light.v-list .v-list-item--link:hover {
  background-color: #00a0e2;
  color: #ffffff;
}