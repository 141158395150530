:root {
  --error-validation-bg-color: rgba(208, 2, 27, 0.9);
}

.feature-toggler {
  height: 100%;
  width: 100%;
  display: flex;
  flex-grow: 1;
  flex-direction: column;
  background-color: white;
  font-size: 1.4rem;
}
.feature-toggler__toggles {
  padding-left: 5rem;
  padding-top: 2rem;
}
.feature-toggler__toggles--toggle {
  display: flex;
  column-gap: 1rem;
  padding-bottom: 0;
  height: 4rem;
}
.feature-toggler__toggles--toggle .v-input--selection-controls {
  padding-top: 0;
  margin-top: 0;
}
.feature-toggler__toggles--toggle ::v-deep .v-text-field {
  max-width: 40%;
  padding-top: 0;
  margin-top: 0;
}
.feature-toggler__toggles--toggle ::v-deep .v-input__slot {
  min-height: 1.8rem;
}
.feature-toggler__toggles--toggle ::v-deep .v-text-field__slot {
  height: 1.8rem;
}
.feature-toggler__toggles--toggle ::v-deep .v-text-field__details {
  display: none;
}