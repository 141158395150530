:root {
  --error-validation-bg-color: rgba(208, 2, 27, 0.9);
}

.v-icon--small {
  font-size: 1.6rem;
  height: 1.6rem;
}
.v-icon--large {
  font-size: 3.6rem;
  height: 3.6rem;
}
.v-icon svg {
  pointer-events: none;
}
.v-icon--primary-rect::v-deep svg rect {
  fill: #2f477c;
}
.v-icon--primary-rect::v-deep:hover svg rect {
  fill: #000000;
}
.v-icon--primary-path::v-deep svg path {
  fill: #2f477c;
}
.v-icon--primary-path::v-deep:hover svg path {
  fill: #000000;
}