.dialog-copy {
  font-size: 1.6rem;
}

.future-cost-warning {
  padding-top: 1.5rem;
  font-size: 1.3rem;
}

.warning-icon {
  padding-bottom: 0.3rem;
}