:root {
  --error-validation-bg-color: rgba(208, 2, 27, 0.9);
}

.row {
  display: none;
}

.v-card .body.v-card__text {
  font-size: 1.4rem;
  margin-bottom: 7rem;
}
.v-card .btn-group.v-card__actions .cancel-btn {
  color: #3a454d;
  border-color: #3a454d;
}
.v-card .btn-group.v-card__actions .ok-btn {
  color: #ffffff;
  background-color: #2f477c;
  border-color: #2f477c;
}

.v-btn {
  font-size: 1.5rem;
}