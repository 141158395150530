:root {
  --error-validation-bg-color: rgba(208, 2, 27, 0.9);
}

.loading {
  font-size: 1.25rem;
}

.pricing-toolbar {
  background-color: #000000;
  color: #ffffff;
  text-decoration-color: #ffffff;
  align-content: center;
}

.v-btn:hover:before,
.v-btn:focus:before {
  color: transparent;
}

.cookie-manager-btn {
  text-decoration: underline;
}

.subheading {
  font-size: 1.25rem;
  color: #ffffff;
  text-decoration-color: #ffffff;
}

.ow-logo {
  padding-top: 5px;
}