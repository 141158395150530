.workpackage-unit .v-messages {
  display: none;
}
.workpackage-unit .d-flex {
  align-items: center;
}
.workpackage-unit .v-input__slot {
  margin: unset !important;
}
.workpackage-unit .v-input--selection-controls__input {
  margin-right: 0;
}
.workpackage-unit .v-input--selection-controls__ripple {
  height: 26px;
  width: 26px;
  left: -12px;
  top: calc(50% - 21px);
  margin: 7px;
}

.circle-icon {
  font-size: 2rem;
  padding-top: 0rem;
  line-height: 2rem !important;
}

.fa-stack {
  line-height: 2rem !important;
  cursor: pointer;
  height: 2.5rem;
}

.unit-complete-col {
  padding-left: 1.6rem !important;
}