:root {
  --error-validation-bg-color: rgba(208, 2, 27, 0.9);
}

.v-card {
  font-size: 1.5rem;
}
.v-card .header-text {
  padding-top: 2rem;
  font-size: 2rem;
  color: black;
}

.v-btn {
  font-size: 1.5rem;
}