:root {
  --error-validation-bg-color: rgba(208, 2, 27, 0.9);
}

.dialog-copy {
  font-size: 1.2rem;
  line-height: 3rem;
  font-weight: 300;
}

.upload-error {
  font-size: 1.2rem;
}

.upload-error-header {
  font-size: 2rem;
  font-weight: bold;
}

.upload-error-name {
  font-size: 1.2rem;
  font-weight: bold;
}

.header > th {
  color: black !important;
  font-size: 1.2rem !important;
}

.run-row {
  font-size: 1.2rem !important;
}
.run-row > td {
  border-bottom: 0.1rem solid rgba(0, 0, 0, 0.12) !important;
}
.run-row > td > span {
  font-size: 1.2rem !important;
}