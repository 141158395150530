:root {
  --error-validation-bg-color: rgba(208, 2, 27, 0.9);
}

.store-group-expand {
  font-size: 1.25rem;
  text-align: right;
}

.store-group-heading {
  text-align: right;
}

.hierarchy__row tr {
  border-bottom: 0.1rem solid #9b9b9b;
}

.spinner-cell {
  padding: 0;
}