:root {
  --error-validation-bg-color: rgba(208, 2, 27, 0.9);
}

.v-btn {
  background-color: #2f477c !important;
  color: white;
  font-size: 14px !important;
  height: 24px !important;
  width: 140px !important;
}
.v-btn.unsaved-kpis {
  background-color: #e95f00 !important;
}
.v-btn.unsaved-kpis.theme--light.v-btn.v-btn--disabled:not(.v-btn--loading) {
  background-color: #e95f00 !important;
}