:root {
  --error-validation-bg-color: rgba(208, 2, 27, 0.9);
}

.margin-splits-grids-menu {
  min-width: 19rem !important;
  max-height: 27rem !important;
}
.margin-splits-grids-menu .v-list {
  padding: 0;
}
.margin-splits-grids-menu .v-list-item {
  min-height: 2rem;
}

.margin-splits-wrapper .open-details-icon {
  font-size: 2rem;
  color: #2f477c;
}
.margin-splits-wrapper .expand-icon {
  color: #ffffff;
  font-size: 2rem;
}
.margin-splits-wrapper .icon-wrapper {
  background-color: #2f477c;
  border-radius: 4px;
  height: 2rem;
}
.margin-splits-wrapper .root {
  font-weight: bold;
  font-size: 1.2rem;
  margin-right: 1.2rem;
  padding: 0.5rem 1rem 0.5rem 1rem;
  align-self: center;
}
.margin-splits-wrapper .root.highlighted-node {
  background-color: #bbdeec;
}
.margin-splits-wrapper .header {
  border: 0.1rem solid #d8d8d8;
  border-width: 0.1rem 0 0.1rem 0;
  text-align: start;
  font-size: 1.2rem;
  width: max-content;
}
.margin-splits-wrapper .header .header-hierarchy {
  font-weight: bold;
  min-width: 18.9rem;
  max-width: 18.9rem;
}
.margin-splits-wrapper .header .header-hierarchy-unit {
  font-weight: bold;
  min-width: 19.2rem;
  max-width: 19.2rem;
}
.margin-splits-wrapper .header .header-split {
  min-width: 22.4rem;
  max-width: 22.4rem;
}
.margin-splits-wrapper .label-part + .label-part {
  margin-left: 1.2rem;
}
.margin-splits-wrapper .grid-dropdown {
  min-width: 16rem;
  max-width: 16rem;
}
.margin-splits-wrapper .hierarchy-name {
  background-color: #e7edef;
  border-radius: 4px;
  box-sizing: content-box;
  font-size: 1.2rem;
  font-weight: bold;
  height: 18px;
  max-width: 16rem;
  min-width: 16rem;
  padding: 5px 4px 7px 1rem;
}
.margin-splits-wrapper .hierarchy-name__label {
  display: block;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}
.margin-splits-wrapper .hierarchy-name.highlighted-node {
  background-color: #bbdeec;
}
.margin-splits-wrapper .buffer-div {
  height: 4rem;
  background-color: #f2f3f4;
}
.margin-splits-wrapper .hierarchy-tree-wrapper {
  overflow: auto;
  max-height: 50rem;
}
.margin-splits-wrapper .hierarchy-tree-wrapper.disabled {
  pointer-events: none;
  position: relative;
  user-select: none;
}
.margin-splits-wrapper .hierarchy-tree-wrapper.disabled:after {
  content: "";
  position: absolute;
  top: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(218, 218, 218, 0.432);
}
.margin-splits-wrapper .treeview-container {
  display: flex;
  height: 100%;
  /* Specifically remove the first border, to stop the whole treeview having a left border */
}
.margin-splits-wrapper .treeview-container .node-line {
  width: 1.7rem;
  height: 50%;
  top: 50%;
  left: -1px;
  border-top: 1px solid #d8d8d8;
  position: absolute;
}
.margin-splits-wrapper .treeview-container .v-treeview-node__label {
  align-items: center;
  display: flex;
  margin-left: 0;
  overflow: visible;
}
.margin-splits-wrapper .treeview-container .v-treeview-node__root i.v-treeview-node__toggle {
  margin-bottom: 0px !important;
  visibility: hidden;
}
.margin-splits-wrapper .treeview-container .v-treeview-node__root .v-treeview-node__label:first-child {
  padding-left: 1rem;
}
.margin-splits-wrapper .treeview-container .v-treeview-node__children {
  border-left: 1px solid #d8d8d8;
  padding-left: 19.2rem;
  /* Overrides to stop trailing lines */
}
.margin-splits-wrapper .treeview-container .v-treeview-node__children .v-treeview-node__root {
  border-left: 1px solid #d8d8d8;
}
.margin-splits-wrapper .treeview-container .v-treeview-node__children .v-treeview-node__root i.v-treeview-node__toggle {
  margin-bottom: 5px !important;
  visibility: hidden;
}
.margin-splits-wrapper .treeview-container .v-treeview-node__children .v-treeview-node__children {
  padding-left: 41.3rem;
}
.margin-splits-wrapper .treeview-container .v-treeview-node__children .v-treeview-node:last-child > .v-treeview-node__children {
  border-left: 1px solid #ffffff;
}
.margin-splits-wrapper .treeview-container .v-treeview-node__children .v-treeview-node:last-child > .v-treeview-node__root > .v-treeview-node__content > .v-treeview-node__label > .node-line {
  border-left: 1px solid #ffffff;
}
.margin-splits-wrapper .treeview-container .v-treeview .v-treeview-node__label .v-btn {
  margin-top: 2px;
  margin-left: 2px;
}
.margin-splits-wrapper .treeview-container .v-treeview > .v-treeview-node > .v-treeview-node__children {
  border: none;
}
.margin-splits-wrapper .treeview-container .v-treeview > .v-treeview-node > .v-treeview-node__root {
  border-top: 1px solid #d8d8d8;
  width: 100%;
}
.margin-splits-wrapper .treeview-container .v-treeview > .v-treeview-node:first-child > .v-treeview-node__root {
  border: none;
}
.margin-splits-wrapper .treeview-container .v-treeview > .v-treeview-node > .v-treeview-node__root > .v-treeview-node__content > .v-treeview-node__label > .node-line {
  display: none;
}
.margin-splits-wrapper .treeview-container .v-treeview > .v-treeview-node > .v-treeview-node__root > .v-treeview-node__toggle {
  display: none;
}
.margin-splits-wrapper .treeview-container .v-treeview-node--leaf > .v-treeview-node__root {
  padding-left: 2rem;
  padding-right: 0;
}
.margin-splits-wrapper .treeview-container .v-treeview-node,
.margin-splits-wrapper .treeview-container .v-treeview-node--leaf {
  margin-left: 0;
}
.margin-splits-wrapper .v-input {
  padding-top: 0.5rem;
  max-width: 16rem;
}
.margin-splits-wrapper .v-input__slot,
.margin-splits-wrapper .v-select__slot {
  background: #f2f3f4;
}
.margin-splits-wrapper .v-select__selections input {
  padding-left: 5px;
  height: 3rem;
}
.margin-splits-wrapper .v-input__slot {
  box-shadow: none;
  padding: 0;
}
.margin-splits-wrapper .v-icon {
  padding-right: 0;
}
.margin-splits-wrapper .v-label {
  position: unset;
  padding-left: 5px;
  font-size: 1.2rem;
}
.margin-splits-wrapper .v-select__selection.v-select__selection--comma {
  padding-left: 5px;
}
.margin-splits-wrapper .v-text-field__details {
  display: none;
}
.margin-splits-wrapper .save-warning {
  color: #d0021b;
  font-size: 1.2rem;
}