.custom-tooltip {
  max-width: 250px;
  overflow-y: auto;
  padding: 5px;
  background-color: white;
  border: 2px solid #ccc;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.2);
}

.error-message {
  color: #c13939;
}

/* Give top padding only to entries following another entry */
.entry + .entry {
  padding-top: 10px;
}