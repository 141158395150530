:root {
  --error-validation-bg-color: rgba(208, 2, 27, 0.9);
}

tr {
  background-color: white;
  border-bottom: solid 1px #a0b3bb;
}

.add-scenario-row {
  background-color: white;
  font-size: 1.3rem;
  border-bottom: unset;
}
.add-scenario-row td {
  padding: 0.4rem 0.7rem;
  vertical-align: middle;
  line-height: 1.7rem;
}

.add-button {
  font-size: 1.3rem;
}

.alert-cell {
  background-color: #f2f3f4 !important;
  border-bottom: 0.1rem solid #f2f3f4 !important;
  min-width: 5.4rem !important;
}