:root {
  --error-validation-bg-color: rgba(208, 2, 27, 0.9);
}

.v-btn-toggle {
  vertical-align: bottom;
  float: right;
}
.v-btn-toggle .v-btn {
  background-color: #ffffff !important;
  color: #2f477c;
  border-color: #2f477c !important;
  border-width: thin;
  width: 8.7rem;
  font-weight: 300;
}
.v-btn-toggle .regular-price-button {
  width: 6rem;
}
.v-btn-toggle .uom-price-button {
  width: 4rem;
}
.v-btn-toggle .v-btn--active {
  background-color: #2f477c !important;
  color: #ffffff !important;
}