:root {
  --error-validation-bg-color: rgba(208, 2, 27, 0.9);
}

.border-left-blue {
  border-left: 6px solid #d3dee6 !important;
}

.float-right {
  float: right;
}