:root {
  --error-validation-bg-color: rgba(208, 2, 27, 0.9);
}

.search-container {
  height: 4.5rem !important;
  border: none !important;
}

.search-field {
  position: absolute;
  min-width: 250px;
}

.work-package-table.v-data-table .v-data-table__wrapper table thead th:nth-child(3), .work-package-table.v-data-table .v-data-table__wrapper table thead th:last-child,
.work-package-table.v-data-table .v-data-table__wrapper table tbody td:last-child, .work-package-table.v-data-table .v-data-table__wrapper table thead th:nth-child(2),
.work-package-table.v-data-table .v-data-table__wrapper table tbody td:nth-child(2), .work-package-table.v-data-table .v-data-table__wrapper table thead th:nth-child(1),
.work-package-table.v-data-table .v-data-table__wrapper table tbody td:nth-child(1) {
  position: -webkit-sticky;
  /* for Safari */
  position: sticky;
  z-index: 1;
}

#export-api-warning {
  color: #d0021b;
}

.scroll {
  max-height: 50rem;
  overflow: scroll;
}

.work-package-table.v-data-table {
  overflow: scroll;
  position: relative;
  max-width: 99%;
}
.work-package-table.v-data-table .content-end.col-border-right {
  -webkit-box-shadow: 4px 0px 0px 0px rgba(0, 0, 0, 0.14);
  -moz-box-shadow: 4px 0px 0px 0px rgba(0, 0, 0, 0.14);
  box-shadow: 4px 0px 0px 0px rgba(0, 0, 0, 0.14);
}
.work-package-table.v-data-table .content-end.col-border-left {
  -webkit-box-shadow: -4px 0px 0px 0px rgba(0, 0, 0, 0.14);
  -moz-box-shadow: -4px 0px 0px 0px rgba(0, 0, 0, 0.14);
  box-shadow: -4px 0px 0px 0px rgba(0, 0, 0, 0.14);
}
.work-package-table.v-data-table .col-border-left {
  border-left: 0.1rem solid #9b9b9b !important;
}
.work-package-table.v-data-table .v-data-table__wrapper table {
  border-collapse: separate;
  /* Don't collapse */
}
.work-package-table.v-data-table .v-data-table__wrapper table thead th:nth-child(1),
.work-package-table.v-data-table .v-data-table__wrapper table tbody td:nth-child(1) {
  min-width: 27rem;
  width: 27rem;
  left: 0;
}
.work-package-table.v-data-table .v-data-table__wrapper table thead th:nth-child(2),
.work-package-table.v-data-table .v-data-table__wrapper table tbody td:nth-child(2) {
  min-width: 10rem;
  width: 10rem;
  left: 27rem;
}
.work-package-table.v-data-table .v-data-table__wrapper table thead th:last-child,
.work-package-table.v-data-table .v-data-table__wrapper table tbody td:last-child {
  min-width: 6rem;
  width: 6rem;
  right: 0;
}
.work-package-table.v-data-table .v-data-table__wrapper table thead th:nth-child(3) {
  min-width: 10rem;
  width: 10rem;
  left: 37rem;
}
.work-package-table.v-data-table .v-data-table__wrapper table thead tr {
  height: 2.2rem;
  font-size: 2.2rem;
}
.work-package-table.v-data-table .v-data-table__wrapper table thead th {
  transform: scale(0.999);
  background-color: #f2f3f4;
  padding: 0 0.5rem;
  height: unset;
}
.work-package-table.v-data-table .v-data-table__wrapper table thead th:not(:last-child) {
  border-right: 0.1rem solid #9b9b9b;
}
.work-package-table.v-data-table .v-data-table__wrapper table thead .v-datatable__progress {
  background-color: #9b9b9b;
}
.work-package-table.v-data-table .v-data-table__wrapper table tbody tr.workpackage td:not(.col-no-border) {
  border-top: 0.2rem solid #9b9b9b;
}
.work-package-table.v-data-table .v-data-table__wrapper table tbody tr.selected td:not(.col-no-border) {
  border-top: 0.2rem solid #00a0e2;
}
.work-package-table.v-data-table .v-data-table__wrapper table tbody tr.white-row td {
  background: #ffffff;
}
.work-package-table.v-data-table .v-data-table__wrapper table tbody tr.spacer-row {
  background: #f2f3f4;
}
.work-package-table.v-data-table .v-data-table__wrapper table tbody tr.spacer-row:last-child td {
  border: 0;
  -webkit-box-shadow: unset;
  -moz-box-shadow: unset;
  box-shadow: unset;
}
.work-package-table.v-data-table .v-data-table__wrapper table tbody tr.button-row td {
  background: #ffffff;
  text-align: right;
}
.work-package-table.v-data-table .v-data-table__wrapper table tbody td {
  color: #4a4a4a;
  height: 2.2rem;
  font-size: 1.4rem;
  vertical-align: top;
}
.work-package-table.v-data-table .v-data-table__wrapper table tbody td:not(.col-no-border) {
  border-bottom: 0.1rem solid #9b9b9b;
}
.work-package-table.v-data-table .v-data-table__wrapper table tbody td.text-small {
  font-size: 1.2rem;
}
.work-package-table.v-data-table .v-data-table__wrapper table tbody td.text-bold {
  font-weight: 600;
}
.work-package-table.v-data-table .v-data-table__wrapper table tbody td.col-fixed-width {
  min-width: 10rem;
}
.work-package-table.v-data-table .v-data-table__wrapper table tbody td.col-unit {
  min-width: 31rem;
}
.work-package-table.v-data-table .v-data-table__wrapper table tbody td.col-border-right:not(:last-child) {
  border-right: 0.1rem solid #9b9b9b !important;
}
.work-package-table.v-data-table .v-data-table__wrapper table tbody td:first-child {
  vertical-align: top;
  padding: 5px 10px;
}
.work-package-table.v-data-table .v-data-table__wrapper table tbody td .inner-table td {
  min-width: 10rem;
  max-width: 10rem;
  border-bottom: unset;
}
.work-package-table.v-data-table .workpackage.archived {
  color: #9b9b9b !important;
}
.work-package-table.v-data-table .workpackage.archived a {
  color: #9b9b9b !important;
}
.work-package-table.v-data-table .workpackage.archived .v-icon {
  color: #9b9b9b;
}
.work-package-table.v-data-table .no-data-text {
  width: 29rem;
  font-size: 1.2rem;
  padding: 1rem 0;
  white-space: normal;
}
.work-package-table.v-data-table .v-text-field {
  padding: unset;
  margin: unset;
}
.work-package-table.v-data-table .new-workpackage .v-icon {
  font-size: 1.5rem;
}
.work-package-table.v-data-table .new-workpackage-buttons {
  min-width: 20rem !important;
}

.panel-header {
  font-size: 1.4rem;
}