:root {
  --error-validation-bg-color: rgba(208, 2, 27, 0.9);
}

.ag-wholesale-hierarchy .row-hierarchy-level-0 {
  border-color: #2c80b1;
}
.ag-wholesale-hierarchy .row-hierarchy-level-0 .ag-cell.name-column {
  color: #00a0e2;
  font-size: 1.8rem;
}
.ag-wholesale-hierarchy .row-hierarchy-level-1 {
  border-color: #2c80b1;
}
.ag-wholesale-hierarchy .row-hierarchy-level-1 .ag-cell.name-column {
  color: #37424a;
  font-size: 1.6rem;
}
.ag-wholesale-hierarchy .row-hierarchy-level-2 {
  border-color: #aecadb;
}
.ag-wholesale-hierarchy .row-hierarchy-level-2 .ag-cell.name-column {
  color: #2f477c;
  font-size: 1.4rem;
}
.ag-wholesale-hierarchy .row-hierarchy-level-3 {
  border-color: #aecadb;
}
.ag-wholesale-hierarchy .row-hierarchy-level-3 .ag-cell.name-column {
  color: #2f477c;
  font-size: 1.2rem;
}
.ag-wholesale-hierarchy .row-hierarchy-level-4 {
  border-color: #aecadb;
}
.ag-wholesale-hierarchy .row-hierarchy-level-4 .ag-cell.name-column {
  color: #37424a;
  font-size: 1rem;
}
.ag-wholesale-hierarchy .row-hierarchy-level-5 {
  border-color: #aecadb;
}
.ag-wholesale-hierarchy .row-hierarchy-level-5 .ag-cell.name-column {
  color: #37424a;
  font-size: 1rem;
}
.ag-wholesale-hierarchy .row-hierarchy-level-3 .ag-cell.name-column,
.ag-wholesale-hierarchy .row-hierarchy-level-5 .ag-cell.name-column {
  font-weight: normal;
}
.ag-wholesale-hierarchy .row-store-group .ag-cell.name-column {
  display: inline-block;
  color: black;
  font-weight: normal;
  text-align: right;
  padding-right: 1rem;
}
.ag-wholesale-hierarchy .row-store-group-end {
  border-top: none;
}
.ag-wholesale-hierarchy .row-store-group-end .ag-cell {
  display: none;
}
.ag-wholesale-hierarchy .expand-icon {
  transform: scale(2);
  color: #2f477c;
}
.ag-wholesale-hierarchy .delta-column {
  background-color: rgba(0, 107, 226, 0.05);
}
.ag-wholesale-hierarchy .ag-root-wrapper {
  border: none;
}
.ag-wholesale-hierarchy .ag-header {
  border-color: #00a0e2;
  border-width: 2px;
}
.ag-wholesale-hierarchy .ag-cell.border-right,
.ag-wholesale-hierarchy .ag-header-cell.border-right,
.ag-wholesale-hierarchy .ag-header-group-cell.border-right {
  border-right: 1px solid var(--ag-border-color, #9b9b9b);
}
.ag-wholesale-hierarchy .ag-cell.border-left,
.ag-wholesale-hierarchy .ag-header-cell.border-left,
.ag-wholesale-hierarchy .ag-header-group-cell.border-left {
  border-left: 1px solid var(--ag-border-color, #9b9b9b);
}
.ag-wholesale-hierarchy .ag-cell.bold-text,
.ag-wholesale-hierarchy .ag-header-cell.bold-text,
.ag-wholesale-hierarchy .ag-header-group-cell.bold-text {
  font-weight: 600;
}