:root {
  --error-validation-bg-color: rgba(208, 2, 27, 0.9);
}

.approvals {
  display: flex;
}

.approval {
  box-sizing: border-box;
  padding: 0 0.5rem;
  width: 4.4rem;
}
.approval:last-child {
  padding-right: 0;
}
.approval:first-child {
  padding-left: 0;
}

.approval + .approval {
  border-left: 1px solid #e7e7e7;
}

.approval-select {
  padding-top: 0.5rem;
}
.approval-select .v-text-field__details {
  display: none;
}
.approval-select .v-select__selections {
  height: 2.8rem;
}
.approval-select .v-input__slot {
  margin-bottom: unset !important;
}