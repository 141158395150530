.form-signin {
  max-width: 33rem;
  padding: 1.5rem;
  margin: 0 auto;
}

.form-signin .form-signin-heading,
.form-signin .checkbox {
  margin-bottom: 1rem;
}

.form-signin .checkbox {
  font-weight: normal;
}

.form-signin .form-control {
  position: relative;
  height: auto;
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
  padding: 1rem;
  font-size: 1.6rem;
}

.form-signin .form-control:focus {
  z-index: 2;
}

.form-signin input[type=email] {
  margin-bottom: -0.1rem;
  border-bottom-right-radius: 0;
  border-bottom-left-radius: 0;
}

.form-signin input[type=password] {
  margin-bottom: 1rem;
  border-top-left-radius: 0;
  border-top-right-radius: 0;
}