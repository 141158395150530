:root {
  --error-validation-bg-color: rgba(208, 2, 27, 0.9);
}

.borderless-panel {
  box-shadow: none;
}
.borderless-panel .expansion-body {
  padding: 1.2rem;
  background-color: #f2f3f4;
}