:root {
  --error-validation-bg-color: rgba(208, 2, 27, 0.9);
}

:root {
  --error-validation-bg-color: rgba(208, 2, 27, 0.9);
}

.actions {
  display: flex;
  flex-wrap: wrap-reverse;
  justify-content: flex-end;
  margin-left: 0.5rem;
}
.actions .v-btn {
  height: 2.4rem;
  font-size: 1.25rem;
}

.pricing-toolbar {
  background-color: #ffffff;
  z-index: 100;
}

.home-link {
  color: #00a0e2;
}

.home-link:hover {
  text-decoration: none;
}

.link-dark {
  color: #ffffff;
}

.nav-link {
  color: #0561a5;
  border-radius: 0%;
  padding-bottom: 0.5rem;
}
.nav-link:hover {
  text-decoration: none;
}
.nav-link.nav-link-dark {
  color: #ffffff;
}

.active-link {
  border-bottom: 0.5rem solid #0561a5;
  padding-bottom: 0rem;
}

.v-btn {
  font-size: 1.5rem;
}

.settings-link {
  color: #2f477c;
}

.align-bottom {
  margin-bottom: -1rem;
}

.pricing-toolbar.v-app-bar--fixed.warning {
  background-color: #ef6c00 !important;
}

.workpackage-selection {
  position: fixed;
  background-color: white;
  width: 100%;
  padding: 3rem 3rem 1rem;
  z-index: 90;
  color: #4a4a4a;
  font-size: 1.4rem;
}
.workpackage-selection .description {
  font-weight: 600;
}
.workpackage-selection .dates {
  color: #37424a;
  font-size: 1.2rem;
}

.avatar {
  margin-right: 1rem;
}

#navbar-logout {
  padding-bottom: 1rem;
  margin-left: 3rem;
}