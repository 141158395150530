.audit {
  height: 100%;
  width: 100%;
  display: flex;
  flex-grow: 1;
  background-color: white;
  font-size: 1.4rem;
}
.audit__container {
  padding: 2rem;
}