:root {
  --error-validation-bg-color: rgba(208, 2, 27, 0.9);
}

.penalty-table {
  overflow: scroll !important;
}

.header > th {
  color: black !important;
  font-size: 1.2rem !important;
}

.penalty-row {
  font-size: 1.2rem !important;
}
.penalty-row > td {
  border-bottom: 0.1rem solid rgba(0, 0, 0, 0.12) !important;
}
.penalty-row > td > span {
  font-size: 1.2rem !important;
}

.economic {
  color: #bcc72f;
}

.architecture {
  color: #9c79c8;
}

.competitor {
  color: #2cb498;
}

.store-group {
  color: #3f7696;
}

.price-pointing {
  color: #be2a71;
}