:root {
  --error-validation-bg-color: rgba(208, 2, 27, 0.9);
}

.v-data-table--fixed-header thead tr:nth-child(1) th {
  box-shadow: none;
  font-weight: 600;
  height: 2rem;
}
.v-data-table--fixed-header thead tr:nth-child(2) th {
  top: 2rem;
  height: 2rem;
}

.main-header th {
  position: relative;
}

.v-list-item__title {
  font-size: 1.5rem;
}

.v-list-item {
  min-height: 35px;
}

.theme--light.v-data-table .v-data-table-header th {
  color: rgba(0, 0, 0, 0.38);
}

.no-data-slot {
  padding-left: 2.2rem;
}

.input-screen-page-wrapper .table-cell::v-deep .tooltipped-truncated-field {
  position: unset;
}

.alt-go-live {
  margin: 0;
  padding: 0;
}
.alt-go-live::v-deep input {
  min-width: 70px;
  padding: 0;
}

.date-attribute-cell {
  position: relative;
}