:root {
  --error-validation-bg-color: rgba(208, 2, 27, 0.9);
}

.suffix {
  padding: 0 0.5rem;
}

.form-container .form-header {
  padding: 0.2rem 1.2rem;
  border-radius: 0.5rem;
  font-size: 1.2rem;
  background-color: #4a4a4a;
  color: #ffffff;
  display: inline-block;
}
.form-container .text-strong {
  font-weight: bold;
}
.form-container .meta-errors {
  color: #ff5252;
}