:root {
  --error-validation-bg-color: rgba(208, 2, 27, 0.9);
}

.rule-content {
  height: 100%;
  overflow: scroll;
}
.rule-content::v-deep .v-label {
  font-size: 1.2rem;
}
.rule-content .formWrapper {
  background-color: #e7e7e7;
  padding: 1.5rem;
}
.rule-content .formWrapper::v-deep .v-label {
  top: 0;
}
.rule-content .formWrapper::v-deep input {
  padding: 0.3rem;
}
.rule-content .formWrapper::v-deep .v-select__selection--comma {
  margin: 0;
  padding: 0.3rem;
}
.rule-content .formWrapper::v-deep .v-select__slot {
  height: 2.6rem;
}
.rule-content .penalty-form {
  min-width: 15rem;
}
.rule-content .chart-container {
  min-width: 57rem;
}
.rule-content .activate-checkbox {
  margin-top: 2rem;
  justify-content: flex-end;
}
.rule-content .tab-title {
  margin-left: 0.5rem;
  margin-top: 0.5rem;
}
.rule-content .headline {
  font-size: 1.5rem !important;
}
.rule-content .traffic-light {
  margin-right: 0.5rem;
}
.rule-content .economic-penalty-traffic-light {
  background-color: #bcc743;
}
.rule-content .competitor-penalty-traffic-light {
  background-color: #00ac92;
}
.rule-content .storeGroup-penalty-traffic-light {
  background-color: #3f7696;
}
.rule-content .architecture-penalty-traffic-light {
  background-color: #9e79c5;
}
.rule-content .pricePointing-penalty-traffic-light {
  background-color: #be2a71;
}
.rule-content .other-penalty-traffic-light {
  background-color: #c2c2c2;
}
.rule-content .tab-content {
  font-size: 1.6rem;
}
.rule-content .btn-block {
  height: calc(100% - 11rem);
}
.rule-content .prefix,
.rule-content .suffix {
  font-size: 1.3rem;
  padding: 0;
}
.rule-content .prefix {
  padding-right: 0.5rem;
}
.rule-content .suffix {
  padding-left: 0.5rem;
}
.rule-content .field-heading {
  font-size: 1.2rem !important;
}
.rule-content .v-input--checkbox {
  font-size: 1.2rem;
}
.rule-content .error-message {
  font-size: 1.2rem;
  margin-right: 0.5rem;
}

.expanded-bottom {
  box-shadow: 0 0.3rem 0.5rem -0.1rem rgba(0, 0, 0, 0.2), 0 0.6rem 1rem 0 rgba(0, 0, 0, 0.14);
  border-top-left-radius: 0;
  border-top-right-radius: 0;
}

.rule-card {
  padding: 1rem 1rem 1rem 1rem;
  transition: none;
}
.rule-card.expanded-top {
  margin-bottom: 0;
  border-bottom-left-radius: 0;
  border-bottom-right-radius: 0;
}

.card-text {
  font-size: 1.25rem;
}

.rule-content {
  padding-bottom: 1rem;
}

.add-pricepointing-text {
  font-size: 1.25rem;
  margin-left: 1rem;
}

.add-pricepointing-container {
  display: flex;
  align-items: center;
  flex: 1 0 0;
}

.add-pricepointing-icon {
  color: #29477a;
  padding-left: 0;
}

.opaque {
  opacity: 0.5;
}