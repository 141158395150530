:root {
  --error-validation-bg-color: rgba(208, 2, 27, 0.9);
}

.file {
  position: relative;
  display: inline-block;
  cursor: pointer;
  height: 4rem;
  width: 100%;
}

.file input {
  min-width: 14rem;
  margin: 0;
  filter: alpha(opacity=0);
  opacity: 0;
}

.file-custom {
  position: absolute;
  top: 0;
  right: 0;
  left: 0;
  z-index: 5;
  height: 4rem;
  padding: 0.5rem 1rem;
  line-height: 1.5;
  color: #555;
  background-color: #fff;
  border: 0.075rem solid #ddd;
  border-radius: 0.25rem;
  box-shadow: inset 0 0.2rem 0.4rem rgba(0, 0, 0, 0.05);
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

.file-custom:after {
  content: "Choose file...";
}

.file-custom:before {
  position: absolute;
  top: -0.075rem;
  right: -0.075rem;
  bottom: -0.075rem;
  z-index: 6;
  display: block;
  content: "Browse";
  height: 4rem;
  padding: 0.5rem 1rem;
  line-height: 1.5;
  color: white;
  background-color: #2f477c;
  border: 0.075rem solid #2f477c;
  border-radius: 0 0.25rem 0.25rem 0;
}

.v-btn {
  font-size: 1.25rem;
}

/* Focus */
.file input:focus ~ .file-custom {
  box-shadow: 0 0 0 0.075rem #fff, 0 0 0 0.2rem #0074d9;
}

.tips {
  text-align: justify;
}
.tips .warning-text {
  font-weight: 800;
}