:root {
  --error-validation-bg-color: rgba(208, 2, 27, 0.9);
}

.rule-entry {
  transition-duration: 5s;
}

.card-text {
  font-size: 1.25rem;
}

.traffic-light {
  color: white;
  cursor: default;
  margin-right: 0.5rem;
}
.traffic-light .headline {
  font-size: 1.5rem !important;
}

.product-proportion {
  flex: 0.6;
  margin-right: 1rem;
  text-align: right;
}

.edit-text {
  align-items: baseline;
  cursor: pointer;
  color: #29477a;
  font-size: 1.25rem;
  height: auto !important;
  letter-spacing: 0;
}
.edit-text:before {
  content: none;
}
.edit-text.theme--light.v-btn.v-btn--disabled {
  color: inherit !important;
}
.edit-text ::v-deep .v-btn__content {
  padding: 0;
}

.expanded {
  transform: rotate(-180deg);
}

.scope-entry {
  word-spacing: 0.6rem;
}

.scope-entry.scope-op {
  color: #006aa7;
}

.scope-entry.scope-br {
  color: #bf4c4f;
}

.scope-default {
  color: black;
  margin-right: 0.6rem;
}

.economic-penalty-traffic-light {
  background-color: #bcc743;
}

.competitor-penalty-traffic-light {
  background-color: #00ac92;
}

.store-group-penalty-traffic-light {
  background-color: #3f7696;
}

.architecture-penalty-traffic-light {
  background-color: #9e79c5;
}

.price-pointing-traffic-light {
  background-color: #be2a71;
}

.other-penalty-traffic-light {
  background-color: #c2c2c2;
}

.inactive-traffic-light {
  background-color: #f0f0f0;
  color: #f0f0f0;
}

.edit-dropdown-icon {
  color: #29477a;
}

.description-text-input {
  padding-top: 0;
  margin-top: -1rem;
  font-size: 1.2rem;
}

.description {
  padding-top: 0.3rem;
}

.description-text,
.product-proportion,
.scope-text,
.store-group-text {
  min-width: 0;
}

.description-text {
  flex: 1.8;
}

.scope-text {
  flex: 3;
}

.store-group-text {
  color: #4a4a4a;
  flex: 2;
}