:root {
  --error-validation-bg-color: rgba(208, 2, 27, 0.9);
}

.transfer-dialog {
  overflow: hidden;
  border-left: 0.5rem solid #2f477c;
}
.transfer-dialog .v-input__slot {
  padding: unset !important;
}
.transfer-dialog.alert-border {
  border-left: 0.5rem solid #d7534e;
}
.transfer-dialog .confirmation {
  text-align: center;
}
.transfer-dialog .alert-banner {
  margin-left: -0.5rem;
  color: white;
}
.transfer-dialog .alert-banner .v-icon {
  color: white;
}
.transfer-dialog .confirm-btns {
  display: flex;
  justify-content: center;
}
.transfer-dialog .workpackage-dropdown {
  border-bottom: 0.2rem solid #2f477c;
  border-radius: unset;
}
.transfer-dialog .workpackage-dropdown .text,
.transfer-dialog .workpackage-dropdown label {
  font-size: 1rem;
  color: #767676 !important;
}
.transfer-dialog .workpackage-dropdown .v-select__slot {
  background-color: #f2f3f4;
}
.transfer-dialog .workpackage-dropdown .v-input__control {
  min-height: 2.4rem !important;
}
.transfer-dialog .workpackage-dropdown input {
  background-color: #f2f3f4;
  padding: 0 0.3rem;
  color: #767676 !important;
}
.transfer-dialog .workpackage-dropdown icon {
  color: #2f477c;
}

.main-section {
  padding: 0 2rem;
}
.main-section .sections {
  margin: 0.6rem 1rem;
  border-right: #c2c2c2 solid 0.1rem;
  font-size: 1.4rem;
}
.main-section .sections.scrollable {
  max-height: 26rem;
  overflow-x: hidden;
}
.main-section .sections .heading {
  margin-bottom: 0.2rem;
}
.main-section .sections .warning-message {
  color: #d0021b;
  margin-top: 1rem;
}
.main-section .sections .transfer-item {
  padding: 0.1rem;
  align-items: center;
}
.main-section .sections .transfer-item input {
  margin-right: 0.6rem;
}
.main-section .sections .transfer-item input:checkedspan::before {
  background: black;
}
.main-section .sections .from-item {
  padding: 0.1rem;
  margin-right: 0.2rem;
}
.main-section .sections .from-item.border {
  border: #c2c2c2 solid 0.1rem;
  padding: unset;
}
.main-section .sections .from-item .heading {
  padding: 0 0.3rem;
  display: flex;
  justify-content: space-between;
}
.main-section .sections .from-item .heading span {
  display: flex;
  align-items: center;
}
.main-section .sections .from-item .heading span input {
  margin: 0 0.6rem;
  padding: 0 0.6rem;
}
.main-section .sections .from-item .options {
  padding: 0 0.3rem;
}
.main-section .sections .from-item .options:first-child {
  border-right: #c2c2c2 solid 0.05rem;
}
.main-section .sections .from-item .options span {
  display: flex;
  width: 100%;
  justify-content: space-between;
  padding: 0.2rem 0;
  align-items: center;
}
.main-section .sections .from-item .options span:first-child {
  margin-top: 0.3rem;
}
.main-section .sections .from-item .options span input {
  margin: 0 0.6rem;
  padding: 0 0.6rem;
}
.main-section .col:last-child {
  border-right: unset;
}