.attribute-info > span {
  font-size: 1.2rem;
}

.pg-dropdown-item-text {
  font-size: 1.5rem !important;
}

.header-text {
  padding-top: 0.5rem;
  font-size: 2rem;
  color: black;
}