:root {
  --error-validation-bg-color: rgba(208, 2, 27, 0.9);
}

.category-container label {
  font-size: 1.2rem;
  font-weight: 600;
  line-height: 2.3rem;
  cursor: pointer;
}
.category-container label span {
  font-size: 1.7rem;
}
.category-container input[type=radio] {
  margin: 0 0.2rem;
}
.category-container .economic {
  color: #bcc72f;
}
.category-container .competition {
  color: #2cb498;
}
.category-container .architecture {
  color: #9c79c8;
}
.category-container .store-group {
  color: #3f7696;
}
.category-container .combined {
  color: #4a4a4a;
}

.toggle-btn-active {
  color: #ffffff !important;
  background-color: #2f477c !important;
  border-color: #2f477c !important;
}

.toggle-btn-inactive {
  color: #2f477c !important;
  border-color: #2f477c !important;
}